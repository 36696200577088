<!--
 * @Description: 房源类型
 * @Author: 琢磨先生
 * @Date: 2022-05-09 11:00:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 10:37:05
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table
      v-loading="loading"
      :data="tableData"
      :border="true"
      row-key="id"
    >
      <el-table-column label="名称" prop="name" width="300"></el-table-column>
      <el-table-column label="停用" width="180">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        prop="description"
        width="500"
      ></el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-building
    :item="current"
    :groups="tableData"
    :reload="loadData"
  ></edit-building>
</template>
<script>
import EditBuilding from "./edit_building.vue";
export default {
  components: {
    EditBuilding,
  },
  data() {
    return {
      loading: false,
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post(`admin/v1/building`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/building/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>